var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "pa-0", attrs: { fluid: "" } }, [
    !_vm.isLoggedInWithProperEmailAddress
      ? _c(
          "div",
          { staticStyle: { "margin-top": "1rem" } },
          [
            _c("p", { staticStyle: { "text-align": "center" } }, [
              _c("b", [_vm._v("Incorrect Account")])
            ]),
            _c("p", { staticStyle: { "text-align": "center" } }, [
              _vm._v(
                _vm._s(
                  "You are logged in with " +
                    _vm.$store.state.loggedInEmail +
                    " but the email link is intended for " +
                    this.cleanedRecipient +
                    "."
                )
              )
            ]),
            _c("p", { staticStyle: { "text-align": "center" } }, [
              _vm._v(
                _vm._s(
                  "Please logout and log in with " + this.cleanedRecipient + "."
                )
              )
            ]),
            _c(
              "v-btn",
              {
                staticClass: "centered",
                attrs: {
                  color: "primary",
                  disabled: _vm.$store.state.loggedInEmail === null
                },
                on: {
                  click: function($event) {
                    return _vm.logout()
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$store.state.loggedInEmail
                      ? "Logout of " + _vm.$store.state.loggedInEmail
                      : "Logout"
                  )
                )
              ]
            )
          ],
          1
        )
      : _vm.showErrorMessage
      ? _c("div", { staticStyle: { "margin-top": "1rem" } }, [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v("There was an error loading.  Please try again.")
          ]),
          _c(
            "p",
            { staticStyle: { "text-align": "center", "font-style": "italic" } },
            [
              _vm._v("If this repeats continually, please contact "),
              _c("a", { attrs: { href: "mailto:support@truple.io" } }, [
                _vm._v("support@truple.io")
              ]),
              _vm._v(".")
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
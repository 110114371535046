<template>
  <v-container fluid class="pa-0">
    <div v-if="!isLoggedInWithProperEmailAddress" style="margin-top: 1rem;">
      <p style="text-align: center;"><b>Incorrect Account</b></p>
      <p style="text-align: center;">{{`You are logged in with ${$store.state.loggedInEmail} but the email link is intended for ${this.cleanedRecipient}.`}}</p>
      <p style="text-align: center;">{{`Please logout and log in with ${this.cleanedRecipient}.`}}</p>
      <v-btn class="centered" color="primary" @click="logout()" :disabled="$store.state.loggedInEmail === null">{{$store.state.loggedInEmail ? `Logout of ${$store.state.loggedInEmail}` : 'Logout'}}</v-btn>
    </div>
    <div v-else-if="showErrorMessage" style="margin-top: 1rem;">
      <p style="text-align: center;">There was an error loading.  Please try again.</p>
      <p style="text-align: center; font-style: italic;">If this repeats continually, please contact <a href="mailto:support@truple.io">support@truple.io</a>.</p>
    </div>

  </v-container>
</template>

<script>
  export default {
    name: 'dailyEmailImages',
    components: {},
    data () {
      return {
        busy: true,
        showErrorMessage: false,
        isSubscribed: false,
        loading: true,
        recipient: null,
        cleanedRecipient: null,
        accountId: null,
        token: null,
        frequency: null
      }
    },
    computed: {
      isLoggedInWithProperEmailAddress () {
        return this.$store.state.loggedInEmail === this.cleanedRecipient
      }
    },
    methods: {
      async logout() {
        await this.auth.logoutAwait('report recipient didn\'t match logged in user', window.location.href)
      },
      loadAndValidateQueryParameters() {
        this.recipient = this.$route.query.recipient;
        this.cleanedRecipient = this.recipient ? decodeURIComponent(this.recipient).toLowerCase() : this.recipient,
        this.accountId = this.$route.query.account;
        this.frequency = this.$route.query.frequency;
      },
      async acceptInviteAndRedirect() {
        this.loading = true

        let params = {
          recipient: this.recipient,
          accountId: this.accountId,
          frequency: this.frequency,
          version: "3"
        }

        let error
        let result = await this.api.dailyEmailImagesAwait(params).catch(err => {
          error = err
          console.log(error)

          if(error && error.indexOf && error.indexOf('NotAuthorizedException') !== -1) {
            this.$swal('There was an issue with your credentials.  Please logout, close the browser, and try again.')
            this.auth.logoutAwait('NotAuthorizedException', window.location.href)
          }
        })

        if(error) {
          this.showErrorMessage = true
          this.loading = false;
          this.busy = false;
          return
        } else {
          await this.$router.push('home')
        }
      }
    },
    mounted() {
      this.loadAndValidateQueryParameters();
      this.acceptInviteAndRedirect()
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
</style>
